import { FC, useEffect, useState } from "react";
import { default as C } from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Crafter from "../components/Crafter";
import Loader from "../components/Loader";
import { useCrafters } from "../providers/CraftersProvider";
import { Profile } from "../types/profile";

interface CarouselProps {}

const Carousel: FC<CarouselProps> = () => {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const { getProfiles } = useCrafters();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profiles = await getProfiles(3);
        setProfiles(profiles as any);
      } catch (error) {
        console.error(error);
      }
    };

    void fetchData();
  }, [getProfiles]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  if (!profiles.length) return <Loader />;

  return (
    <C responsive={responsive} sliderClass="crafters" arrows swipeable infinite>
      {profiles
        .filter((crafter) => crafter.firstname?.length)
        .map((crafter: Profile, key: any) => {
          return (
            <div key={key} className="crafter">
              <Crafter
                firstname={crafter.firstname}
                shortId={crafter.shortId}
                profilePicture={crafter.profilePicture}
                job={crafter.job}
                openToWork={crafter.openToWork}
              />
            </div>
          );
        })}
    </C>
  );
};

export default Carousel;
