import * as d3 from "d3";
import { Profile } from "../types/profile";
import { renderProfiles } from "./renderProfiles";
import { startTransition } from "./startTransition";

const radius = 40;
const width = 960;
const height = 500;

export const initAutoAnimation = async (profiles: Profile[]): Promise<void> => {
  const positions = generatePositions(profiles.length);
  const svg = createSVG(width, height);
  const g = createGroup(svg);

  renderProfiles(g, profiles, positions, radius);
  startTransition(g, positions, width, height, radius);
};

const generatePositions = (numProfiles: number): [number, number][] => {
  const step = radius * 2;
  const theta = Math.PI * (3 - Math.sqrt(5));
  return Array.from({ length: numProfiles }, (_, i) => {
    const r = step * Math.sqrt((i += 0.5)),
      a = theta * i;
    return [width / 2 + r * Math.cos(a), height / 2 + r * Math.sin(a)];
  });
};

const createSVG = (
  width: number,
  height: number,
): d3.Selection<SVGSVGElement, unknown, HTMLElement, any> => {
  return d3
    .select<SVGSVGElement, unknown>("#profile-svg")
    .attr("viewBox", `0 0 ${width} ${height}`)
    .style("width", "100%")
    .style("height", "auto");
};

const createGroup = (
  svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
): d3.Selection<SVGGElement, unknown, HTMLElement, any> => {
  return svg.append<SVGGElement>("g");
};
