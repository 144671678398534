import * as d3 from "d3";
import { Profile } from "../types/profile";

const radius = 40;
const width = 960;
const height = 500;

export const initManualAnimation = async (
  profiles: Profile[],
): Promise<void> => {
  const positions = generatePositions(profiles.length);
  createZoomableChart(profiles, positions, radius);
};

const generatePositions = (numProfiles: number): [number, number][] => {
  const step = radius * 2;
  const theta = Math.PI * (3 - Math.sqrt(5));
  return Array.from({ length: numProfiles }, (_, i) => {
    const r = step * Math.sqrt((i += 0.5)),
      a = theta * i;
    return [width / 2 + r * Math.cos(a), height / 2 + r * Math.sin(a)];
  });
};

const createZoomableChart = (
  profiles: Profile[],
  positions: [number, number][],
  radius: number,
): any => {
  const svg = d3
    .select<SVGSVGElement, unknown>("#profile-svg")
    .attr("viewBox", `0 0 ${width} ${height}`);

  const g = svg.append<SVGGElement>("g");

  const zoom = d3
    .zoom<SVGSVGElement, unknown>()
    .scaleExtent([0.1, 5])
    .on("zoom", (event) => {
      g.attr("transform", event.transform);
    });

  svg.call(zoom);

  const profileGroups = g
    .selectAll<SVGGElement, Profile>("g")
    .data(profiles)
    .join("g")
    .attr(
      "transform",
      (_, i) => `translate(${positions[i][0]}, ${positions[i][1]})`,
    );

  profileGroups
    .append("clipPath")
    .attr("id", (_, i) => `clip-circle-${i}`)
    .append("circle")
    .attr("cx", 0)
    .attr("cy", 0)
    .attr("r", radius);

  profileGroups
    .append("image")
    .attr("x", -radius)
    .attr("y", -radius)
    .attr("width", radius * 2)
    .attr("height", radius * 2)
    .attr("xlink:href", (d) => d.profilePicture ?? "")
    .attr("clip-path", (_, i) => `url(#clip-circle-${i})`)
    .on(
      "click",
      (event, d) =>
        (window.location.href = `https://socraft.community/profile/${d.shortId}`),
    );

  profileGroups
    .append("text")
    .attr("y", radius + 15)
    .attr("text-anchor", "middle")
    .text((d) => d.firstname ?? "");

  // Function to handle zoom reset
  const reset = () => {
    svg.transition().call(zoom.transform, d3.zoomIdentity);
  };

  // Adding zoom and pan controls
  const random = () => {
    const [x, y] = positions[Math.floor(Math.random() * positions.length)];
    svg.transition().call(
      zoom.transform,
      d3.zoomIdentity
        .translate(width / 2, height / 2)
        .scale(40)
        .translate(-x, -y),
    );
  };

  return Object.assign(svg.node() ?? {}, {
    zoomIn: () => svg.transition().call(zoom.scaleBy, 2),
    zoomOut: () => svg.transition().call(zoom.scaleBy, 0.5),
    zoomRandom: random,
    zoomReset: reset,
  });
};
