import { ReactElement } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Carousel from "./pages/Carousel";
import Mosaic from "./pages/Mosaic";
import { CraftersProvider } from "./providers/CraftersProvider";

const App = (): ReactElement => {
  return (
    <BrowserRouter>
      <CraftersProvider>
        <Routes>
          <Route path="/" element={<Mosaic />} />
          <Route path="/carousel" element={<Carousel />} />
        </Routes>
      </CraftersProvider>
    </BrowserRouter>
  );
};

export default App;
