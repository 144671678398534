import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import { useCrafters } from "../providers/CraftersProvider";
import { Profile } from "../types/profile";
import { initAutoAnimation } from "../utils/auto";
import { initManualAnimation } from "../utils/manual";

interface MosaicProps {}

const Mosaic: React.FC<MosaicProps> = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const location = useLocation();

  const [profiles, setProfiles] = useState<Profile[]>([]);
  const { getProfiles } = useCrafters();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profiles = await getProfiles(0);
        setProfiles(profiles as any);
      } catch (error) {
        console.error(error);
      }
    };

    void fetchData();
  }, [getProfiles]);

  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(location.search);
      const animationEnabled = urlParams.get("animation") === "true";

      animationEnabled
        ? initAutoAnimation(profiles)
        : initManualAnimation(profiles);
      setLoaded(true);
    };

    profiles.length && !loaded && void fetchData();
  }, [location, profiles, loaded]);

  const handleMouseEnter = (e: Event) => {
    const target = e.currentTarget as SVGElement;
    target.style.opacity = "0.5";
    target.style.transform = "scale(1.05)";
    target.style.transition = "opacity 0.3s ease, transform 0.3s ease";
    target.style.cursor = "pointer";
  };

  const handleMouseLeave = (e: Event) => {
    const target = e.currentTarget as SVGElement;
    target.style.opacity = "1";
    target.style.transform = "scale(1)";
  };

  useEffect(() => {
    const svgElement = document.getElementById("profile-svg");
    if (svgElement) {
      const svgChildren = svgElement.querySelectorAll(
        "image, circle, rect, path",
      );
      svgChildren.forEach((child) => {
        child.addEventListener("mouseenter", handleMouseEnter);
        child.addEventListener("mouseleave", handleMouseLeave);
      });

      return () => {
        svgChildren.forEach((child) => {
          child.removeEventListener("mouseenter", handleMouseEnter);
          child.removeEventListener("mouseleave", handleMouseLeave);
        });
      };
    }
  }, [loaded]);

  return (
    <div className="pad">
      {!loaded && <Loader />}
      <svg id="profile-svg"></svg>
    </div>
  );
};

export default Mosaic;
