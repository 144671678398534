import axios from "axios";
import { createContext, FC, useContext } from "react";
import { Profile } from "../types/profile";

export const CraftersContext = createContext<CraftersContextProps | undefined>(
  undefined,
);

interface CraftersProviderProps {
  children: React.ReactNode;
}

interface CraftersContextProps {
  getProfiles: (orderBy: number) => Promise<Profile>;
}

export const CraftersProvider: FC<CraftersProviderProps> = ({ children }) => {
  const getProfiles = async (orderBy: number): Promise<Profile> => {
    try {
      const requestUrl: string = `https://my.socraft.ch/api/public/profiles?orderBy=${orderBy}`;
      const result = await axios.get(requestUrl);

      return result.data.profiles;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const craftersContextValue: CraftersContextProps = {
    getProfiles,
  };

  return (
    <CraftersContext.Provider value={craftersContextValue}>
      {children}
    </CraftersContext.Provider>
  );
};

export const useCrafters = (): CraftersContextProps => {
  const directoryContext = useContext<CraftersContextProps | undefined>(
    CraftersContext,
  );

  if (!directoryContext) {
    throw new Error("useCrafters must be used within a CraftersProvider");
  }

  return directoryContext;
};
