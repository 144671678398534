import * as d3 from "d3";

export const startTransition = (
  g: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
  positions: [number, number][],
  width: number,
  height: number,
  radius: number,
): void => {
  let currentTransform: [number, number, number] = [
    width / 2,
    height / 2,
    height,
  ];

  function transitionStep(): void {
    const d = positions[Math.floor(Math.random() * positions.length)];
    const i = d3.interpolateZoom(currentTransform, [...d, radius * 2 + 1]);

    g.transition()
      .delay(250)
      .duration(i.duration)
      .attrTween(
        "transform",
        () => (t: number) => transform((currentTransform = i(t))),
      )
      .on("end", transitionStep);
  }

  function transform([x, y, r]: [number, number, number]): string {
    return `
      translate(${width / 2}, ${height / 2})
      scale(${height / r / 2})
      translate(${-x}, ${-y})
    `;
  }

  transitionStep();
};
