import * as d3 from "d3";
import { Profile } from "../types/profile";

export const renderProfiles = (
  g: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
  profiles: Profile[],
  positions: [number, number][],
  radius: number,
): void => {
  g.selectAll<SVGGElement, Profile>("g")
    .data(profiles)
    .enter()
    .append("g")
    .each(function (d, i) {
      const group = d3.select(this);

      group
        .append("image")
        .attr("x", positions[i][0] - radius)
        .attr("y", positions[i][1] - radius)
        .attr("width", radius * 2)
        .attr("height", radius * 2)
        .attr("xlink:href", d.profilePicture ?? "")
        .attr("clip-path", `circle(${radius}px at ${radius}px ${radius}px)`);

      group
        .append("text")
        .attr("x", positions[i][0])
        .attr("y", positions[i][1] + radius + 15)
        .attr("text-anchor", "middle")
        .text(d.firstname ?? "");
    });
};
