import { FC } from "react";

interface CrafterProps {
  firstname?: string;
  shortId?: string;
  profilePicture?: string;
  job?: string;
  openToWork?: boolean;
}

const Crafter: FC<CrafterProps> = ({
  firstname,
  shortId,
  profilePicture,
  job,
  openToWork,
}) => {
  if (!firstname) return <></>;

  return (
    <div className="crafter">
      <img
        onClick={() =>
          window.open(`https://socraft.community/profile/${shortId}`)
        }
        className="avatar"
        alt="avatar"
        src={profilePicture}
        loading="lazy"
      />
      <div className="infos">
        <span className="firstname">{firstname}</span>
        {job && <span className="job">{job}</span>}
        {openToWork && <span className="open-to-work">Open to work</span>}
      </div>
    </div>
  );
};

export default Crafter;
